module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'uvdp4b76',
    dataset: process.env.GATSBY_SANITY_DATASET || 'industrial-design',
  },
  gatsby: {
    siteTitle: 'Industrial Design Student Showcase | Academy of Art University',
    siteDescription:
      'At the School of Industrial Design, you don’t just imagine a better world, you learn the skills to help create one. Our programs are designed to create innovative thinkers that can design with empathy, discover logical solutions, and create aesthetics appropriate to the project.',
    siteUrl: 'https://indportfolios.academyart.edu', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
    userTwitter: '@academy_of_art', // Change for Twitter Cards
    author: 'Academy of Art University', // Author for RSS author segment and SEO schema
    authorUrl: 'https://www.academyart.edu', // URL used for author and publisher schema, can be a social profile or other personal site
    shortTitle: 'Industrial Design Student Showcase', // Used for App manifest e.g. Mobile Home Screen
  },
};
